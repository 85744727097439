import React from 'react';

function Block({icon, title, subtitle, description, className}) {

  return (
    <div className={`Block ${className ? className : ''}`}>
      {icon &&
        <div className="block-icon">
          {icon.src
            ? <a href={icon.link}><img src={icon.src} alt={title} /></a>
            : <img src={icon} alt={title} />
          }
        </div>
      }
      {title && <h2 className="block-title">{title}</h2>}
      {subtitle && <h2 className="block-subtitle">{subtitle}</h2>}
      {description && <div className="block-description" dangerouslySetInnerHTML={{__html: description}} />}
    </div>
  );
}

export default Block;