import React from 'react';
import Context from '../../tools/context';
import {Block, Button} from '../../components';
import './index.scss';

const GiftCard = () => {
  const giftCardConfig = Context?.config?.giftCard;

  return (
    <div className="GiftCard">
      {giftCardConfig &&
        <>
          <div
            className="hero"
            style={{
              backgroundImage: giftCardConfig.background
                ? `url(${giftCardConfig.background})`
                : ""
            }}
          >
            <div className="left">
              {giftCardConfig.logo &&
                <div className="logo-wrapper">
                  <img src={giftCardConfig.logo.src} alt={giftCardConfig.logo.alt} className="logo"/>
                </div>
              }
              {giftCardConfig.title && <h1 className="title">{giftCardConfig.title}</h1>}
              {giftCardConfig.subtitle && <h2 className="subtitle">{giftCardConfig.subtitle}</h2>}
              {giftCardConfig.link &&
                <a href={"/carte-cadeau#register-form"}>
                  <Button className={"btn-link btn-inverted"}>{giftCardConfig.link}</Button>
                </a>
              }
            </div>
            <div className="right">
              {giftCardConfig.description &&
                <div className="description">
                  {giftCardConfig.description.title && <h3 className="title">{giftCardConfig.description.title}</h3>}
                  {giftCardConfig.description.blocks &&
                    <div className="blocks">
                      {giftCardConfig.description.blocks.map((block, index) => (
                        <Block
                          key={index}
                          icon={block.icon}
                          title={block.title}
                          subtitle={block.subtitle}
                          description={block.description}
                        />
                      ))}
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default GiftCard;
