import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Search({value, icon, className, placeholder, handleChange}) {

  return (
    <div className={`Search ${className ? className : ''}`}>
      {icon &&
        <FontAwesomeIcon icon={icon} color={"#bfbfbf"} />
      }
      <input
        type={'input'}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
}

export default Search;