import React, {useState} from 'react';

function Header({links, logos}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Header">
      <div className="header-wrapper">
        {logos &&
          <div className="logos-wrapper">
            {logos.map((logo, index) =>
              <a href={logo.href}>
                <img key={index} className={"logo"} src={logo.src} alt={logo.alt} width={logo.width} />
              </a>
            )}
          </div>
        }
        {links &&
          <div className="menu">
            <div className={`burger-menu ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen((isOpen) => !isOpen)}>
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
            <div className={`links ${isOpen ? 'active' : ''}`}>
              {links.map((link, index) => (
                <div className="link" key={index}>
                  <a href={link.url}>{link.name}</a>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Header;