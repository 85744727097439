import {getPartner, getQueryString} from './toolbox';

const partner = getPartner();
let Context = {};

if (partner) {
  const config = require(`../config/${partner}/config.json`);
  Context = {
    partner,
    config,
  };
} else if (localStorage.getItem('context')) {
  Context = JSON.parse(localStorage.getItem('context'));
} else {
  const config = require(`../config/tca/config.json`);
  Context = {
    partner: 'tca',
    config,
  }
}

localStorage.setItem('context', JSON.stringify(Context));

export default Context;
