import {Route, Routes} from 'react-router-dom';
import {Footer, Header} from './components';
import Context from './tools/context';
import {initDocument} from './tools/toolbox';
import Home from './pages/Home';
import GiftCard from './pages/GiftCard';
import NotFound from './pages/NotFound';
import React from 'react';
import './App.scss';

const routes = [
  {
    path: '/',
    component: <Home />,
    header: true,
    footer: true,
  },
  {
    path: '/carte-cadeau',
    component: <GiftCard />,
    header: true,
    footer: true,
  },
  {
    path: '*',
    component: <NotFound />,
    header: true,
    footer: true,
  }
];

function App() {
  initDocument(Context.config);

  return (
    <div className="App">
      {Context?.config?.header &&
        <Header links={Context.config.header.links} logos={Context.config.header.logos} />}
      <div className="Page">
        <Routes>
          {routes.map((route, index) => (
            <Route path={route.path} element={route.component} key={index} />
          ))}
        </Routes>
      </div>
      {Context?.config?.footer &&
        <Footer items={Context.config.footer.items} socials={Context.config.footer.socials} />}
    </div>
  )
}

export default App;