const getQueryString = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const partnerDomains = {
  "bordeauxmoncommerce.fr": "bordeauxmoncommerce",
  "sauvetoncommerce.fr": "sauvetoncommerce",
  "tourismebyca.fr": "tca",
};

const getPartner = () => {
  let domain = window.location.hostname;

  if (partnerDomains[domain]) {
    return partnerDomains[domain];
  } else if (getQueryString('part')) {
    return getQueryString('part');
  } else {
    return 'tca';
  }
}

const initDocument = (config) => {
  let root = document.documentElement;
  for (let key in config.style) {
    root.style.setProperty(`--${key}`, config.style[key]);
  }

  const setIcon = (type, url, sizes) => {
    let link = document.querySelector(`link[href*='${url}']`);

    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', type);
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.setAttribute('href', url);
    link.setAttribute('sizes', sizes);
  }

  if (config.meta) {
    document.title = config.meta.title;

    {config.meta.icons?.map((icon) => (
      setIcon(icon.rel, icon.href, icon.sizes)
    ))}
  }
}

export {
  getPartner,
  getQueryString,
  initDocument,
}
