import React from 'react';
import {Button} from '../../components';
import './index.scss';

const NotFound = () => {
  return (
    <div className="NotFound">
      <div className="main-container">
        <h1 className="title">Erreur 404 - Non trouvé</h1>
        <a href={'/'}>
          <Button className={"btn-link"}>Retourner à l'accueil</Button>
        </a>
      </div>
    </div>
  );
};

export default NotFound;
