import React from 'react';

function Footer({items, socials}) {
  return (
    <div className="Footer">
      <div className="footer-wrapper">
        {items &&
          <div className="items-wrapper">
            {items.map((item, index) => (
              <div dangerouslySetInnerHTML={{__html: item}} className={"item"} key={index} />
            ))}
          </div>
        }
        {socials &&
          <div className="socials-wrapper">
            {socials.map((social, index) =>
              <a href={social.href}>
                <img key={index} className={"social"} src={social.src} alt={social.alt} width={social.width} />
              </a>
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default Footer;