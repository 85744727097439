import React, {useState} from 'react';
import Context from '../../tools/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faLocationArrow} from '@fortawesome/free-solid-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {Search} from './components';
import './index.scss';
import {Block, Button} from '../../components';

function Home() {
  const [searchControl, setSearchControl] = useState("");
  const [searchLocation, setSearchLocation] = useState("");

  if (!Context?.config) return (<div>Config not found</div>);

  return (
    <div className="Home">
      {Context.config.home &&
        <>
          <div className="search-wrapper">
            {Context.config.home.carousel &&
              <Carousel
                animationHandler={"slide"}
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                useKeyboardArrows={false}
                swipeable={false}
                autoPlay
                infiniteLoop
              >
                {Context.config.home.carousel.images?.map((image, index) => (
                  <div>
                    <img src={image} alt="landscape" key={index + "_image"}/>
                    <div className="search-block" key={index + "_search-block"}>
                      <div className="search-content">
                        {Context.config.home.carousel?.title &&
                          <h1
                            className={"title"}
                            style={{textAlign: Context.config.home.carousel?.title ? "" : "center"}}
                          >
                            {Context.config.home.carousel?.title}
                          </h1>
                        }
                        {Context.config.home.carousel.badge &&
                          <div className="badge">
                            <div className={"badge-content"}
                                 dangerouslySetInnerHTML={{__html: Context.config.home.carousel?.badge}}/>
                          </div>
                        }
                      </div>
                      {Context.config.home.carousel.search &&
                        <div className="search-group">
                          <Search
                            value={searchControl}
                            icon={faSearch}
                            placeholder={"Un commerce ?"}
                            handleChange={(value) => setSearchControl(value)}
                            className={"search-control"}
                          />
                          <Search
                            value={searchLocation}
                            icon={faLocationArrow}
                            placeholder={"Où ?"}
                            handleChange={(value) => setSearchLocation(value)}
                            className={"search-location"}
                          />
                          <Button className={"search-btn"}>
                            <span>Rechercher</span>
                            <FontAwesomeIcon icon={faSearch} color={"#fff"}/>
                          </Button>
                        </div>
                      }
                      {Context.config.home.carousel.partnersOp &&
                        <section className="partners-op">
                          <a href={Context.config.home.carousel.partnersOp}>
                            <Button className="link-btn">Découvrir les opérations partenaires</Button>
                          </a>
                        </section>
                      }
                    </div>
                  </div>
                ))}
              </Carousel>
            }
          </div>
          <div className="main-container">
            {Context.config.home.description &&
              <h2 className="description">
                <div dangerouslySetInnerHTML={{__html: Context.config.home.description}}/>
              </h2>
            }
            {Context.config.home.partners &&
              <div className="partners">
                <div className="partner-carousel">
                  <Carousel
                    animationHandler={"slide"}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    useKeyboardArrows={false}
                    swipeable
                    autoPlay
                    infiniteLoop
                  >
                    {Context.config.home.partners.map((partner, index) => (
                      <div className="partner" key={index}>
                        <a href={partner.url} style={{cursor: partner.url ? "pointer" : ""}}>
                          <img src={partner.logo} alt={partner.name}/>
                        </a>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <span>Nos villes et nos entreprises s'engagent</span>
              </div>
            }
            <div className="hero">
              {Context.config.home.deals &&
                <section className="blocks deals">
                  <h3>Découvrez les 3 types de bonnes affaires de commerçants</h3>
                  <div className="blocks-container">
                    {Context.config.home.deals?.map((deal, index) => (
                      <Block
                        key={index}
                        icon={deal.icon}
                        title={deal.title}
                        description={deal.description}
                      />
                    ))}
                  </div>
                </section>
              }
              {Context.config.home.infos &&
                <section className="blocks infos">
                  <h3>Malgré le confinement, tous les commerçants peuvent être aidés</h3>
                  <div className="blocks-container">
                    {Context.config.home.infos?.map((info, index) => (
                      <Block
                        key={index}
                        icon={info.icon}
                        title={info.title}
                      />
                    ))}
                  </div>
                </section>
              }
              {Context.config.home.howItWorks &&
                <section className="blocks how-it-works">
                  <h3>Comment ça marche ?</h3>
                  <div className="blocks-container">
                    {Context.config.home.howItWorks?.map((h, index) => (
                      <Block
                        key={index}
                        icon={h.icon}
                        title={h.title}
                        description={h.description}
                      />
                    ))}
                  </div>
                </section>
              }
              {Context.config.home.referencing &&
                <section
                  className="referencing"
                  style={{
                    backgroundImage: Context.config.home.referencing?.backgroundImage ? `url(${Context.config.home.referencing?.backgroundImage})` : ""
                  }}
                >
                  <p>
                    Entreprises, profitez de cette opportunité !<br/>
                    Référencez-vous ici et créez votre bon solidaire <strong>sans frais et sans commission</strong>
                  </p>
                  <a href="https://ticket-commercant.fr">
                    <Button className="btn-inverted link-btn">Référencez-vous gratuitement</Button>
                  </a>
                </section>
              }
              {Context.config.home.newPartnersList &&
                <section className="blocks new-partners-list">
                  <h3>Nos nouveaux partenaires</h3>
                  <div className="blocks-container">
                    {Context.config.home.newPartnersList.map((partner) => (
                      <Block
                        key={partner.name}
                        icon={partner.icon}
                      />
                    ))}
                  </div>
                </section>
              }
              {Context.config.home.partnersList &&
                <section className="blocks partners-list">
                  <h3>Nos partenaires</h3>
                  <div className="blocks-container">
                    {Context.config.home.partnersList.map((partner) => (
                      <Block
                        key={partner.name}
                        icon={partner.icon}
                      />
                    ))}
                  </div>
                </section>
              }
              {Context.config.home.partnersOp &&
                <section className="partners-op">
                  <a href={Context.config.home.partnersOp.link}>
                    <Button className="link-btn">Découvrir les opérations partenaires</Button>
                  </a>
                </section>
              }
              {Context.config.home.talkAboutUs &&
                <section className="blocks talk-about-us">
                  <h3>Ils parlent de nous</h3>
                  {Context.config.home.talkAboutUs.map((talk, index) => (
                    <div className={"talk"} key={index}>
                      <p>{talk}</p>
                    </div>
                  ))}
                </section>
              }
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Home;